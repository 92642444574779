<template>
    <div class="dropdown">
        <input
                v-model.trim="inputValue"
                class="dropdown-input"
                type="text"
                placeholder="Поиск"
                @input="inputSearchField"
        />

        <div
                v-show="inputValue && apiLoaded && Object.keys(selectedItem).length === 0"
                class="dropdown-list">
            <div
                    @click="selectItem(item)"
                    v-for="(item, index) in options"
                    :key="'dropdownSearch'+index"
                    class="dropdown-item">
                {{ item.education_speciality_name}} ({{item.language.native_name}}) - Набор {{item.admission_year}}
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                selectedItem: {},
                inputValue: "",
                apiLoaded: false,
            };
        },
        props: ["options", "value"],
        mounted() {
            this.apiLoaded = true;
            if (this.value) {
                console.log('dropdown', this.value)
                this.selectedItem = this.value;
                const item = this.value
                this.inputValue = `${item.education_speciality_name} (${item.language.native_name}) - Набор ${item.admission_year}`;
            }
        },

        updated() {
            if (this.value) {
                console.log('dropdown', this.value)
                this.selectedItem = this.value;
                const item = this.value
                this.inputValue = `${item.education_speciality_name} (${item.language.native_name}) - Набор ${item.admission_year}`;
            }
        },

        methods: {
            inputSearchField(e) {
                if (Object.keys(this.selectedItem).length !== 0) {
                    this.selectedItem = {};
                }
                this.$emit("search", e.target.value);
            },
            selectItem(item) {
                this.selectedItem = item;
                this.inputValue = `${item.education_speciality_name} (${item.language.native_name}) - Набор ${item.admission_year}`;
                this.$emit("change", item);
            },
        },
    };
</script>

<style>
    .dropdown {
        position: relative;
        width: 100%;
        max-width: 600px;
        margin: 0;
    }

    .dropdown-input,
    .dropdown-selected {
        width: 100%;
        padding: 10px 16px;
        border: 1px solid transparent;
        background: #edf2f7;
        line-height: 1.5em;
        outline: none;
        border-radius: 8px;
    }

    .dropdown-input:focus,
    .dropdown-selected:hover {
        background: #fff;
        border-color: #e2e8f0;
    }

    .dropdown-input::placeholder {
        opacity: 0.7;
    }

    .dropdown-selected {
        font-weight: bold;
        cursor: pointer;
    }

    .dropdown-list {
        z-index: 1;
        position: absolute;
        width: 100%;
        max-height: 500px;
        margin-top: 4px;
        overflow-y: auto;
        background: #ffffff;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        border: 1px solid #bbb;
    }

    .dropdown-item {
        display: flex;
        width: 100%;
        padding: 11px 16px;
        cursor: pointer;
    }

    .dropdown-item:hover {
        background: #edf2f7;
    }

    .dropdown-item-flag {
        max-width: 24px;
        max-height: 18px;
        margin: auto 12px auto 0px;
    }
</style>