<template>
  <div class="container">
    <h4 class="text-center my-4">Список студентов</h4>
    <a class="mt-2 mb-4"
       href="https://app.powerbi.com/view?r=eyJrIjoiNGEzNjIyMDAtMzg5Ny00ODJlLWE0NTctNTFjOTYxYjdhZmM2IiwidCI6IjFkMTUyNDRlLWMyNTctNGMzYi04MzEyLTQ2ZjljZjZjNWIxNCIsImMiOjl9">Аналитика
      по студентам</a>

    <PrimePreloader v-if="loading"/>

    <div v-else>
      <div class="mt-4">
        <div class="m-3" style="max-width: 100px">
          <select class="form-select" name="" id="" @change="changeCourse($event)">
            <option v-for="(course, course_index) in [1,2,3,4]" :value="course" :key="course_index"
                    :selected="course==course_id">
              {{ course }} курс
            </option>
          </select>
        </div>
        <!--        :rowsPerPageOptions="[10, 20, 50, 100, 200]"-->
        <!--        filterDisplay="menu"-->
        <!--        :globalFilterFields="['barcode','last_name','first_name','middle_name']"-->
        <!--        v-model:filters="filters"-->

        <!-- input ID2 -->
        <input type="hidden" id="alldata">
        <!-- end input ID2 -->

        <!-- Table Start -->

        <DataTable :value="studentsList_form.studentsListInfos" showGridlines stripedRows responsiveLayout="scroll">
          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                        <InputText v-model="name" placeholder="Поиск студента"/>
                        <button @click="getStudentList"><i class="pi pi-search"/></button>
                    </span>
            </div>
          </template>
          <template #empty>
            Студент не найден
          </template>
          <template #loading>
            Идет поиск студентов. Пожалуйста подождите.
          </template>

          <Column field="barcode" header="Баркод">
            <template #body="{data}">
              <router-link :to="'/student/my-profile?student_id=' + data.id" target="_blank"
                           class="text-decoration-none">
                {{ data.barcode }}
              </router-link>

            </template>
          </Column>
          <Column field="last_name" header="ФИО" sortable>
            <template #body="{data}">
              <div v-if="data.middle_name == null">
                {{ capitalizeFirstLetter(data.last_name) }} {{ capitalizeFirstLetter(data.first_name) }}
                ({{ capitalizeFirstLetter(data.last_name_eng) }} {{ capitalizeFirstLetter(data.first_name_eng) }})
                <br>
                <br>
                ИИН {{ data.studentDataAdditional[0]?.iin }}
              </div>
              <div v-else>
                {{ capitalizeFirstLetter(data.last_name) }} {{ capitalizeFirstLetter(data.first_name) }}
                {{ capitalizeFirstLetter(data.middle_name) }} ({{ capitalizeFirstLetter(data.last_name_eng) }}
                {{ capitalizeFirstLetter(data.first_name_eng) }})
                <br>
                <br>
                ИИН {{ data.studentDataAdditional[0]?.iin }}
              </div>

            </template>
          </Column>
          <Column field="studentGroups.name" header="Группа" filterField="studentGroups.name">
            <template #body="{data}">


              <div v-if="data.studentGroups == null">
                <a href="#" @click="editStudentGroup(data.id)" class="text-danger">
                  У студента нет группы
                </a>
              </div>
              <div v-else>
                <a href="#" @click="editStudentGroup(data.id)">
                  {{ data.studentGroups.name }}
                </a>

              </div>


            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                         placeholder="Поиск по группе"/>
            </template>

          </Column>
          <Column field="study_course" header="Курс">

            <template #body="{data}">
              <a href="#" @click="editStudentCourse(data.id)">
                {{ data.study_course }}
              </a>
            </template>

            <!--                        <template #filter="{filterModel}">-->
            <!--                            <Dropdown v-model="filterModel.value" :options="studyCourses" placeholder="Любой"-->
            <!--                                      class="p-column-filter" :showClear="true">-->
            <!--                                <template #value="slotProps">-->
            <!--                                    <span v-if="slotProps.value">{{slotProps.value}}</span>-->
            <!--                                    <span v-else>{{slotProps.placeholder}}</span>-->
            <!--                                </template>-->
            <!--                                <template #option="slotProps">-->
            <!--                                    {{slotProps.option}}-->
            <!--                                </template>-->
            <!--                            </Dropdown>-->
            <!--                        </template>-->

          </Column>
          <Column field="studyLevel.name" header="Уровень обучения">
            <template #body="{data}">
              <a href="#" @click="editStudentStudyLevel(data.id)">
                {{ data.studyLevel.name }}
              </a>
            </template>

            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="studyLevels" placeholder="Любой"
                        class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                  <span v-if="slotProps.value">{{ slotProps.value }}</span>
                  <span v-else>{{ slotProps.placeholder }}</span>
                </template>
                <template #option="slotProps">
                  <span>{{ slotProps.option }}</span>
                </template>
              </Dropdown>
            </template>

          </Column>
          <Column field="studyLanguage.native_name" header="Язык обучения">

            <template #body="{data}">
              <a href="#" @click="editStudentStudyLanguage(data.id)">
                {{ data.studyLanguage.native_name }}
              </a>
            </template>
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="studyLanguages" placeholder="Любой"
                        class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                  <span v-if="slotProps.value">{{ slotProps.value }}</span>
                  <span v-else>{{ slotProps.placeholder }}</span>
                </template>
                <template #option="slotProps">
                  <span>{{ slotProps.option }}</span>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column field="paymentType.full_name" header="Источник финансирования"></Column>
          <Column field="education_program_id" header="ОП">
            <template #body="{data}">

              <div v-if="data.educationProgram == null">
                <span class="text-danger">У студента нет ОП</span>
              </div>
              <div v-else>
                {{ data.educationProgram.education_speciality_code }}
                {{ data.educationProgram.education_speciality_name }} -
                {{ data.educationProgram.admission_year }}

              </div>
            </template>

            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="educationPrograms" placeholder="Любой"
                        class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                  <span v-if="slotProps.value">{{ slotProps.value }}</span>
                  <span v-else>{{ slotProps.placeholder }}</span>
                </template>
                <template #option="slotProps">

                  <span>{{ slotProps.option }}</span>
                </template>
              </Dropdown>
            </template>

            <!--            <template #filter="{filterModel}">-->
            <!--              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Поиск по ОП"/>-->
            <!--            </template>-->
            <!--            <template #filter="{filterModel}">-->
            <!--              <Dropdown v-model="filterModel.value" :options="educationProgram.education_speciality_name" placeholder="Any" class="p-column-filter" :showClear="true">-->
            <!--                <template #value="slotProps">-->
            <!--                  <span>{{slotProps.education_speciality_name}}</span>-->
            <!--                </template>-->
            <!--                <template #option="slotProps">-->
            <!--                  <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>-->
            <!--                </template>-->
            <!--              </Dropdown>-->
            <!--            </template>-->

          </Column>
          <Column>

            <template #body="{data}">
              <Button icon="pi pi-user-edit" @click="editStudent(data.id)" class="mb-2"/>
              <Button label="ID2" @click="openImportFormID2Dialog(data.id)"/>
            </template>


          </Column>
          <Column header="Действия">

            <template #body="{data}">

              <ul>
                <li class="mb-2">
                  <router-link :to="'/student-transcript?barcode=' + data.barcode" target="_blank"
                               class="text-decoration-none">
                    Транскрипт
                  </router-link>
                </li>
                <li class="mb-2">
                  <router-link :to="'/student-current-attestation?barcode=' + data.barcode" target="_blank"
                               class="text-decoration-none">
                    Атестация
                  </router-link>
                </li>
                <li class="mb-2">
                  <a :href="'https://back.uib.kz/student/transcript/print-transcript?group_id=' + data.study_group_id"
                     target="_blank" class="text-decoration-none">
                    Транскрипт группы
                  </a>
                </li>

                <li class="mb-2" v-if="!data.studentPreviousEducationDocument">
                  <a href="#" @click="addStudentPreviousEducationDocument(data.id)" class="text-decoration-none">
                    Добавить предыдущий документ об образовании
                  </a>
                </li>
                <li class="mb-2" v-if="data.studentPreviousEducationDocument">
                  <a href="#" @click="openInfoStudentPreviousEducationDocument(data.id)" class="text-decoration-none">
                    Посмотреть предыдущий документ об образовании
                  </a>
                </li>

                <li class="mb-2" v-if="!data.studentEnteredUniversityData">
                  <a href="#" @click="addStudentEnteredUniversity(data.id)" class="text-decoration-none">
                    Добавить предыдущий документ об университете
                  </a>
                </li>
                <li class="mb-2" v-if="data.studentEnteredUniversityData">
                  <a href="#" @click="openInfoStudentEnteredUniversity(data.id)" class="text-decoration-none">
                    Посмотреть предыдущий документ об университете
                  </a>
                </li>


                <li class="mb-2">
                  <router-link :to="`/office-registration/old-ratings?barcode=${data.barcode}`" target="_blank"
                               class="text-decoration-none">
                    Дисциплины OLDMOODLE
                  </router-link>
                </li>

                <li class="mb-2">
                  <router-link :to="`/office-registration/difference?id=${data.id}`" target="_blank"
                               class="text-decoration-none">
                    Разница
                  </router-link>
                </li>

              </ul>


            </template>

          </Column>
          <Column header="Наличие долгов">
            <template #body="{data}">
              <div v-if="data?.studentDebt?.total_debt !== null && data?.studentDebt?.total_debt >200">
                <p>{{ data.studentDebt.total_debt }}</p>
                <Button icon="pi pi-key" @click="changeStudentBarcode(data.barcode)"/>
              </div>
              <div v-else>
                -
              </div>
            </template>
          </Column>


        </DataTable>
        <Paginator :rows="10"
                   :totalRecords="studentsList_form.pages.totalCount"
                   @page="handlePageChange($event)"></Paginator>
        <!-- Table End -->


        <Dialog v-model:visible="display" :style="{width: '50vw'}">
          <template #header>
            <h5>Поменять образовательную программу</h5>
          </template>

          <div style="height: 400px" class="col-md-12 mt-4">
            <div>
              <div class="row mt-4">
                <div class="col-md-3">
                  <p>Образовательная программа</p>
                </div>
                <div class="col-md-9">
                  <DropdownListEP
                      :options="options"
                      :value="item"
                      @change="changeSelectEducationProgram"
                      @search="onSearch"/>
                </div>
              </div>
            </div>
          </div>

          <template #footer>
            <Button label="Нет" icon="pi pi-times" class="p-button-text" @click="closeDialog"/>
            <Button label="Да" icon="pi pi-check" @click="putStudentData"/>
          </template>
        </Dialog>

        <Dialog v-model:visible="groupsDisplay" :style="{width: '50vw'}">
          <template #header>
            <h5>Поменять группу студента</h5>
          </template>

          <div style="height: 400px" class="col-md-12 mt-4">
            <div>
              <div class="row mt-4">
                <div class="col-md-3">
                  <p>Группа</p>
                </div>
                <div class="col-md-9">
                  <DropdownListStudentGroups
                      :options="studentGroupsOptions"
                      :value="item"
                      @change="changeSelectStudentGroup"
                      @search="onSearchStudentGroups"/>
                </div>
              </div>
            </div>
          </div>

          <template #footer>
            <Button label="Нет" icon="pi pi-times" class="p-button-text" @click="closeDialogGroups"/>
            <Button label="Да" icon="pi pi-check" @click="putStudentData"/>
          </template>
        </Dialog>


        <Dialog v-model:visible="courseDisplay" :style="{width: '600px', height: '350px'}">
          <template #header>
            <h5>Поменять курс студента</h5>
          </template>

          <div style="height: 400px" class="col-md-12 mt-4">
            <div>
              <div class="row mt-4">
                <div class="col-md-3">
                  <p>Курс</p>
                </div>
                <div class="col-md-9">
                  <select class="form-control form-select" name="study_course" id="study_course"
                          @input="changeSelectStudentCourse($event)">
                    <option selected>Выберите курс</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <template #footer>
            <Button label="Нет" icon="pi pi-times" class="p-button-text" @click="closeDialogCourse"/>
            <Button label="Да" icon="pi pi-check" @click="putStudentData"/>
          </template>
        </Dialog>


        <Dialog v-model:visible="studyLevelDisplay" :style="{width: '50vw'}">
          <template #header>
            <h5>Поменять уровень обучения студента</h5>
          </template>

          <div style="height: 400px" class="col-md-12 mt-4">
            <div>
              <div class="row mt-4">
                <div class="col-md-3">
                  <p>Уровень обучения</p>
                </div>
                <div class="col-md-9">
                  <select class="form-control form-select"
                          @input="changeSelectStudentStudyLevel($event)">
                    <option
                        v-for="(studyLevels, index) in [{id: 0, name: 'Выберите уровень обучения'}, ...studentsList_form.studyLevel]"
                        :value="studyLevels.id"
                        :key="index">{{ studyLevels.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <template #footer>
            <Button label="Нет" icon="pi pi-times" class="p-button-text" @click="closeDialogStudyLevel"/>
            <Button label="Да" icon="pi pi-check" @click="putStudentData"/>
          </template>
        </Dialog>


        <Dialog v-model:visible="studyLanguageDisplay" :style="{width: '50vw'}">
          <template #header>
            <h5>Поменять язык обучения студента</h5>
          </template>

          <div style="height: 400px" class="col-md-12 mt-4">
            <div>
              <div class="row mt-4">
                <div class="col-md-3">
                  <p>Уровень обучения</p>
                </div>
                <div class="col-md-9">
                  <select class="form-control form-select"
                          @input="changeSelectStudentStudyLanguage($event)">
                    <option
                        v-for="(language, index) in [{id: 0, name: 'Выберите язык обучения'}, ...studentsList_form.languages]"
                        :value="language.id"
                        :key="index">{{ language.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <template #footer>
            <Button label="Нет" icon="pi pi-times" class="p-button-text" @click="closeDialogStudyLanguage"/>
            <Button label="Да" icon="pi pi-check" @click="putStudentData"/>
          </template>
        </Dialog>

        <Dialog v-model:visible="barcodeDisplay" :style="{width: '50vw'}">
          <template #header>
            <h5>Дать доступ к экзамену?</h5>
          </template>
          <template #footer>
            <Button label="Нет" icon="pi pi-times" class="p-button-text" @click="closeDialogBarcode"/>
            <Button label="Да" icon="pi pi-check" @click="updateDebt"/>
          </template>
        </Dialog>


        <Dialog v-model:visible="addStudentPreviousEducationDocumentDisplay" :style="{width: '50vw'}" :closable="false">
          <template #header>
            <h5>Добавить предыдущий документ об образовании</h5>
          </template>

          <div style="height: 400px" class="col-md-12 mt-4">

            <div class="row mt-4">
              <div class="col-md-3">
                <p>Предыдущий документ об образовании (на казахском)</p>
              </div>
              <div class="col-md-9">
                <input id="doc_kaz" type="text" class="form-control"
                       placeholder="Предыдущий документ об образовании (на казахском)"
                       @change="changePreviousEducationDocumentData('doc_kz', $event)">
              </div>
            </div>


            <div class="row mt-4">
              <div class="col-md-3">
                <p>Предыдущий документ об образовании (на русском)</p>
              </div>
              <div class="col-md-9">
                <input id="doc_ru" type="text" class="form-control"
                       placeholder="Предыдущий документ об образовании (на русском)"
                       @change="changePreviousEducationDocumentData('doc_ru', $event)">
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-md-3">
                <p>Предыдущий документ об образовании (на английском)</p>
              </div>
              <div class="col-md-9">
                <input id="doc_en" type="text" class="form-control"
                       placeholder="Предыдущий документ об образовании (на английском)"
                       @change="changePreviousEducationDocumentData('doc_en', $event)">
              </div>
            </div>


          </div>

          <template #footer>
            <Button label="Закрыть" icon="pi pi-times" class="p-button-text"
                    @click="closeStudentPreviousEducationDocument"/>
            <Button label="Сохранить" icon="pi pi-check" @click="savePreviousEducationDocument"/>
          </template>
        </Dialog>


        <Dialog v-model:visible="infoStudentPreviousEducationDocumentDisplay" :style="{width: '50vw'}"
                :closable="false">
          <template #header>
            <h5>Предыдущий документ об образовании</h5>
          </template>

          <div style="height: 400px" class="col-md-12 mt-4">

            <div class="row mt-4">
              <div class="col-md-3">
                <p>Предыдущий документ об образовании (на казахском)</p>
              </div>
              <div class="col-md-9">
                <input id="info_doc_kz" type="text" class="form-control"
                       placeholder="Предыдущий документ об образовании (на казахском)"
                       :value="studentsList_form.studentsListInfos.find(i=>i.id == infoStudentId).studentPreviousEducationDocument.doc_kz"
                       @change="changePreviousEducationDocumentData('doc_kz', $event)">
              </div>
            </div>


            <div class="row mt-4">
              <div class="col-md-3">
                <p>Предыдущий документ об образовании (на русском)</p>
              </div>
              <div class="col-md-9">
                <input id="info_doc_ru" type="text" class="form-control"
                       placeholder="Предыдущий документ об образовании (на русском)"
                       :value="studentsList_form.studentsListInfos.find(i=>i.id == infoStudentId).studentPreviousEducationDocument.doc_ru"
                       @change="changePreviousEducationDocumentData('doc_ru', $event)">
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-md-3">
                <p>Предыдущий документ об образовании (на английском)</p>
              </div>
              <div class="col-md-9">
                <input id="info_doc_en" type="text" class="form-control"
                       placeholder="Предыдущий документ об образовании (на английском)"
                       :value="studentsList_form.studentsListInfos.find(i=>i.id == infoStudentId).studentPreviousEducationDocument.doc_en"
                       @change="changePreviousEducationDocumentData('doc_en', $event)">
              </div>
            </div>


          </div>

          <template #footer>
            <Button label="Сохранить" icon="pi pi-check" class="p-button-text"
                    @click="updateInfoStudentPreviousEducationDocument"/>
            <Button label="Закрыть" icon="pi pi-times" class="p-button-text"
                    @click="closeInfoStudentPreviousEducationDocument"/>
          </template>
        </Dialog>

        <Dialog v-model:visible="addStudentEnteredUniversityDisplay" :style="{width: '50vw'}" :closable="false">
          <template #header>
            <h5>Добавить предыдущий документ об университете</h5>
          </template>

          <div style="height: 400px" class="col-md-12 mt-4">

            <div class="row mt-4">
              <div class="col-md-3">
                <p>Предыдущий документ об университете (на казахском)</p>
              </div>
              <div class="col-md-9">
                <input id="doc_kaz" type="text" class="form-control"
                       placeholder="Предыдущий документ об университете (на казахском)"
                       @change="changeEnteredUniversityData('entered_university_kz', $event)">
              </div>
            </div>


            <div class="row mt-4">
              <div class="col-md-3">
                <p>Предыдущий документ об университете (на русском)</p>
              </div>
              <div class="col-md-9">
                <input id="doc_ru" type="text" class="form-control"
                       placeholder="Предыдущий документ об университете (на русском)"
                       @change="changeEnteredUniversityData('entered_university_ru', $event)">
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-md-3">
                <p>Предыдущий документ об университете (на английском)</p>
              </div>
              <div class="col-md-9">
                <input id="doc_en" type="text" class="form-control"
                       placeholder="Предыдущий документ об университете (на английском)"
                       @change="changeEnteredUniversityData('entered_university_en', $event)">
              </div>
            </div>


          </div>

          <template #footer>
            <Button label="Закрыть" icon="pi pi-times" class="p-button-text"
                    @click="closeStudentEnteredUniversity"/>
            <Button label="Сохранить" icon="pi pi-check" @click="saveEnteredUniversity"/>
          </template>
        </Dialog>

        <!-- ImportFromID2 dialog -->
        <Dialog header="Импорт данных из ID2" :visible="displayImportFromID2" :closable="false" :modal="true"
                :style="{width: '100%', maxWidth: '600px'}">
          <div class="my-3">
            <div class="row my-2">
              <div class="col-md-5">Данные преподавателя</div>
              <div class="col-md-7 fw-bold">{{ userNameFront }}</div>
            </div>
            <div class="row my-2">
              <div class="col-md-5">Данные из ID2</div>
              <div class="col-md-7 fw-bold">{{ userNameID2 }}</div>
            </div>
          </div>
          <template #footer>
            <Button label="Закрыть" icon="pi pi-times" @click="closeImportFromID2Dialog" class="p-button-text"/>
            <Button label="Сохранить" icon="pi pi-save"
                    :disabled="disabledSaveImportFromID2"
                    :loading="loadingSaveImportFromID2"
                    @click="saveImportFromID2"
                    autofocus/>
          </template>
        </Dialog>
        <!-- end ImportFromID2 dialog -->


        <Dialog v-model:visible="infoStudentEnteredUniversityDisplay" :style="{width: '50vw'}"
                :closable="false">
          <template #header>
            <h5>Предыдущий документ об университете</h5>
          </template>

          <div style="height: 400px" class="col-md-12 mt-4">

            <div class="row mt-4">
              <div class="col-md-3">
                <p>Предыдущий документ об университете (на казахском)</p>
              </div>
              <div class="col-md-9">
                <input id="info_doc_kz" type="text" class="form-control"
                       placeholder="Предыдущий документ об университете (на казахском)"
                       :value="studentsList_form.studentsListInfos.find(i=>i.id == infoStudentId).studentEnteredUniversityData.entered_university_kz"
                       @change="changeEnteredUniversityData('entered_university_kz', $event)">
              </div>
            </div>


            <div class="row mt-4">
              <div class="col-md-3">
                <p>Предыдущий документ об университете (на русском)</p>
              </div>
              <div class="col-md-9">
                <input id="info_doc_ru" type="text" class="form-control"
                       placeholder="Предыдущий документ об университете (на русском)"
                       :value="studentsList_form.studentsListInfos.find(i=>i.id == infoStudentId).studentEnteredUniversityData.entered_university_ru"
                       @change="changeEnteredUniversityData('entered_university_ru', $event)">
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-md-3">
                <p>Предыдущий документ об университете (на английском)</p>
              </div>
              <div class="col-md-9">
                <input id="info_doc_en" type="text" class="form-control"
                       placeholder="Предыдущий документ об университете (на английском)"
                       :value="studentsList_form.studentsListInfos.find(i=>i.id == infoStudentId).studentEnteredUniversityData.entered_university_en"
                       @change="changeEnteredUniversityData('entered_university_en', $event)">
              </div>
            </div>


          </div>

          <template #footer>
            <Button label="Сохранить" icon="pi pi-check" class="p-button-text"
                    @click="updateInfoStudentEnteredUniversity"/>
            <Button label="Закрыть" icon="pi pi-times" class="p-button-text"
                    @click="closeInfoStudentEnteredUniversity"/>
          </template>
        </Dialog>

      </div>
    </div>


  </div>
</template>

<script>
// import {FilterMatchMode, FilterOperator} from 'primevue/api';
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import DropdownListStudentGroups from "@/components/common/DropdownListStudentGroups.vue";
import DropdownListEP from "@/components/common/DropdownListEP.vue";
import {toCapitalize} from "@/utils/helpers/string.helpers";
import {
  convertDateToTimestamp
} from "@/utils/helpers/date.helpers"

export default {
  name: "StudentsList",
  components: {
    DropdownListStudentGroups,
    DropdownListEP
  },
  data() {
    return {
      page: 1,
      course_id: 1,
      name: '',
      // filters: {
      //   'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      //   'barcode': {
      //     operator: FilterOperator.AND,
      //     constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
      //   },
      //   'last_name': {
      //     operator: FilterOperator.AND,
      //     constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
      //   },
      //   'first_name': {
      //     operator: FilterOperator.AND,
      //     constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
      //   },
      //   'middle_name': {
      //     operator: FilterOperator.AND,
      //     constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
      //   },
      //   'studentGroups.name': {
      //     operator: FilterOperator.AND,
      //     constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
      //   },
      //   'educationProgram.education_speciality_name': {
      //     operator: FilterOperator.AND,
      //     constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
      //   },
      //   //'study_course': {value: null, matchMode: FilterMatchMode.EQUALS},
      //   'studyLevel.name': {value: null, matchMode: FilterMatchMode.EQUALS},
      //   'studyLanguage.native_name': {value: null, matchMode: FilterMatchMode.EQUALS},
      //   'education_program_id': {value: null, matchMode: FilterMatchMode.EQUALS},
      // },
      studyCourses: [
        '1', '2', '3', '4'
      ],
      studyLevels: ['Бакалавриат', 'Магистратура (1 годичное,профильное)', 'Магистратура (1,5 годичное, профильное)',
        'Магистратура (2 годичное, научно-педагогическое)', 'Докторантура (научно-педагогическое направление)', 'MBA', 'EMBA'],
      studyLanguages: ['русский язык', 'қазақ тілі', 'English'],
      educationPrograms: ['0'],
      loading: true,
      display: false,
      options: [],
      studentGroupsOptions: [],
      groupsDisplay: false,
      courseDisplay: false,
      studyLevelDisplay: false,
      studyLanguageDisplay: false,
      barcodeDisplay: false,
      addStudentPreviousEducationDocumentDisplay: false,
      infoStudentPreviousEducationDocumentDisplay: false,
      addStudentEnteredUniversityDisplay: false,
      infoStudentEnteredUniversityDisplay: false,
      infoStudentId: null,
      formStudentData: {},
      formAdditionalData: {},
      displayImportFromID2: false,
      disabledSaveImportFromID2: false,
      loadingSaveImportFromID2: false,
      userNameFront: '',
      userNameID2: '',
    }
  },
  computed: {
    ...mapState('studentsList', ['studentsList_form']),
  },

  methods: {
    ...mapActions('studentsList', ['GET_STUDENTS_LIST', 'GET_EDUCATION_PROGRAMS_BY_NAME',
      'PUT_STUDENT_DATA', 'GET_STUDENT_GROUPS_BY_NAME', 'GET_STUDY_LEVEL_NAMES', 'PUT_STUDENT_DEBT',
      'POST_STUDENT_PREVIOUS_EDUCATION_DOCUMENT', 'UPDATE_STUDENT_PREVIOUS_EDUCATION_DOCUMENT', 'POST_UPDATE_STUDENT_DATA',
      'UPDATE_STUDENT_ENTERED_UNIVERSITY', 'POST_STUDENT_ENTERED_UNIVERSITY', 'POST_UPDATE_STUDENT_ADDITIONAL_DATA']),
    ...mapMutations('studentsList', ['SET_SELECTED_EDUCATION_PROGRAM_ID', 'SET_EDIT_STUDENT_ID',
      'SET_SELECTED_STUDENT_GROUP_ID', 'SET_SELECTED_STUDENT_COURSE', 'SET_SELECTED_STUDENT_STUDY_LEVEL_ID',
      'SET_SELECTED_STUDENT_STUDY_LANGUAGE_ID', 'SET_BARCODE', 'SET_STUDENT_PREVIOUS_EDUCATION_DOCUMENT_DATA',
      'SET_PREVIOUS_EDUCATION_DOCUMENT_STUDENT_ID', 'SET_ENTERED_UNIVERSITY_STUDENT_ID', 'SET_STUDENT_ENTERED_UNIVERSITY_DATA',
      'SET_OBJECT_EDUCATION_DOCUMENT_NULL', 'SET_OBJECT_UNIVERSITY_DOCUMENT_NULL']),
    toCapitalize,
    convertDateToTimestamp,
    async handlePageChange(event) {
      this.page = event.page + 1;
      await this.GET_STUDENTS_LIST({course_id: this.course_id, name: this.name, page: this.page})
    },
    importData() {
      let allData = document.getElementById('alldata').value
      allData = allData.replaceAll(`'`, `"`)

      if (allData) {
        const allDataObj = JSON.parse(allData)

        allDataObj.birth_date = allDataObj.birth_date.split(".").reverse().join("-")
        //allDataObj.valid_from = this.convertDateToTimestamp(allDataObj.valid_from)
        //allDataObj.valid_to = this.convertDateToTimestamp(allDataObj.valid_to)

        allDataObj.last_name = this.toCapitalize(allDataObj.last_name)
        allDataObj.first_name = this.toCapitalize(allDataObj.first_name)
        allDataObj.middle_name = this.toCapitalize(allDataObj.middle_name)
        allDataObj.last_name_en = this.toCapitalize(allDataObj.last_name_en)
        allDataObj.first_name_en = this.toCapitalize(allDataObj.first_name_en)

        return allDataObj
      }
      return null
    },

    openImportFormID2Dialog(studentId) {

      const user = this.studentsList_form.studentsListInfos.find(i => i.id === studentId)
      this.userNameFront = `${user?.last_name} ${user?.first_name}`

      const dataID2 = this.importData()
      if (dataID2) {
        this.formStudentData = {
          student_id: user.id,
          first_name: dataID2.first_name,
          last_name: dataID2.last_name,
          middle_name: dataID2.middle_name,
          first_name_eng: dataID2.first_name_en,
          last_name_eng: dataID2.last_name_en,
        }
        this.formAdditionalData = {
          student_data_id: user.id,
          birth_date: dataID2.birth_date,
          //birth_place: dataID2.birth_place,
          doc_number: dataID2.document_number,
          iin: dataID2.iin,
          doc_given_org: dataID2.issuer,
          doc_given_date: dataID2.valid_from,
          //doc_date_end: dataID2.valid_to,
        }

        this.userNameID2 = `${dataID2?.last_name} ${dataID2?.first_name}`
        this.disabledSaveImportFromID2 = false
      } else {
        this.userNameID2 = `Данные из ID2 не импортированы`
        this.disabledSaveImportFromID2 = true
      }

      this.displayImportFromID2 = true
    },
    closeImportFromID2Dialog() {
      this.displayImportFromID2 = false
    },

    async saveImportFromID2() {
      this.loadingSaveImportFromID2 = true
      console.log(this.formStudentData, 'saveImportFromID2 formStudenData')
      console.log(this.formAdditionalData, 'saveImportFromID2 formAdditionalData')

      const res = await this.POST_UPDATE_STUDENT_DATA(this.formStudentData)
      const resAdd = await this.POST_UPDATE_STUDENT_ADDITIONAL_DATA(this.formAdditionalData)
      if (res && resAdd) {
        this.closeImportFromID2Dialog()
        this.$message({title: 'Данные успешно обновлены'})

        await this.GET_STUDENTS_LIST({course_id: this.course_id, name: this.name})
      }

      this.loadingSaveImportFromID2 = false
    },

    async editStudent(editStudentId) {
      this.display = true
      console.log(editStudentId, 'editStudentId')
      await this.SET_EDIT_STUDENT_ID(editStudentId)

    },
    editStudentGroup(editStudentId) {
      this.groupsDisplay = true
      this.SET_EDIT_STUDENT_ID(editStudentId)
    },
    editStudentCourse(editStudentId) {
      this.courseDisplay = true
      this.SET_EDIT_STUDENT_ID(editStudentId)
    },
    editStudentStudyLevel(editStudentId) {
      this.studyLevelDisplay = true
      this.SET_EDIT_STUDENT_ID(editStudentId)
    },
    editStudentStudyLanguage(editStudentId) {
      this.studyLanguageDisplay = true
      this.SET_EDIT_STUDENT_ID(editStudentId)
    },
    async changeSelectEducationProgram(educationProgram) {
      console.log(educationProgram, 'changeSelectEducationProgramData')
      this.educationProgram = educationProgram
      if (typeof educationProgram.id != "undefined") {
        await this.SET_SELECTED_EDUCATION_PROGRAM_ID(educationProgram.id)
      }

    },
    async changeSelectStudentGroup(studentGroup) {
      console.log(studentGroup, 'studentGroup')
      await this.SET_SELECTED_STUDENT_GROUP_ID(studentGroup)
    },
    async changeStudentBarcode(barcode) {
      this.barcodeDisplay = true
      console.log(barcode);
      await this.SET_BARCODE(barcode)
    },
    async updateDebt() {
      this.barcodeDisplay = false
      this.display = false
      this.groupsDisplay = false
      this.courseDisplay = false
      this.studyLevelDisplay = false
      this.studyLanguageDisplay = false
      await this.PUT_STUDENT_DEBT().then(async res => {
        if (res.success == true) {
          this.$message({title: 'Обновление', text: 'Данные успешно обновились'})
          this.loading = true
          await this.GET_STUDENTS_LIST({course_id: this.course_id, name: this.name})
          this.loading = false;
        } else {
          let errorText = '';
          for (let err of res.errors[0]) {
            errorText += err.message + '\n'
          }
          this.$error({title: 'Ошибка при обновлении', text: errorText})
        }
      })
    },
    async changeSelectStudentCourse(e) {
      const value = e.target.value
      console.log(value, 'changeSelectStudentCourse')
      await this.SET_SELECTED_STUDENT_COURSE(value)
    },
    async changeSelectStudentStudyLevel(e) {
      const value = e.target.value
      console.log(value, 'changeSelectStudentStudyLevel')
      await this.SET_SELECTED_STUDENT_STUDY_LEVEL_ID(value)
    },
    async changeSelectStudentStudyLanguage(e) {
      const value = e.target.value
      console.log(value, 'changeSelectStudentStudyLanguage')
      await this.SET_SELECTED_STUDENT_STUDY_LANGUAGE_ID(value)
    },

    onSearch(search) {
      if (search.length > 3) {
        this.GET_EDUCATION_PROGRAMS_BY_NAME(search).then(json => (this.options = json));
      }
    },
    onSearchStudentGroups(search) {
      this.GET_STUDENT_GROUPS_BY_NAME(search).then(json => (this.studentGroupsOptions = json));
    },

    closeDialog() {
      this.display = false
    },
    closeDialogGroups() {
      this.groupsDisplay = false
    },
    closeDialogCourse() {
      this.courseDisplay = false
    },
    closeDialogStudyLevel() {
      this.studyLevelDisplay = false
    },
    closeDialogStudyLanguage() {
      this.studyLanguageDisplay = false
    },
    closeDialogBarcode() {
      this.barcodeDisplay = false
    },
    async putStudentData() {
      this.display = false
      this.groupsDisplay = false
      this.courseDisplay = false
      this.studyLevelDisplay = false
      this.studyLanguageDisplay = false
      this.barcodeDisplay = false
      await this.PUT_STUDENT_DATA().then(async res => {
        if (res.success == true) {
          this.$message({title: 'Обновление', text: 'Данные успешно обновились'})
          this.loading = true
          await this.GET_STUDENTS_LIST({course_id: this.course_id, name: this.name})
          this.loading = false;
        } else {
          let errorText = '';
          for (let err of res.errors[0]) {
            errorText += err.message + '\n'
          }
          this.$error({title: 'Ошибка при обновлении', text: errorText})
        }
      })
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    async changeCourse(e) {
      this.course_id = e.target.value

      this.loading = true
      await this.GET_STUDENTS_LIST({course_id: this.course_id, name: this.name, page: 1})
      this.loading = false;
    },

    // Предыдущий документ об образовании

    addStudentPreviousEducationDocument(student_data_id) {
      console.log(student_data_id, 'student_data_id')
      this.addStudentPreviousEducationDocumentDisplay = true
      this.SET_PREVIOUS_EDUCATION_DOCUMENT_STUDENT_ID(student_data_id)
    },
    closeStudentPreviousEducationDocument() {
      this.addStudentPreviousEducationDocumentDisplay = false
    },
    openInfoStudentPreviousEducationDocument(student_data_id) {
      console.log(student_data_id, 'info student_data_id')
      this.infoStudentId = student_data_id
      this.infoStudentPreviousEducationDocumentDisplay = true
      this.SET_PREVIOUS_EDUCATION_DOCUMENT_STUDENT_ID(student_data_id)
    },
    closeInfoStudentPreviousEducationDocument() {
      this.infoStudentPreviousEducationDocumentDisplay = false
      this.SET_OBJECT_EDUCATION_DOCUMENT_NULL();
    },
    async updateInfoStudentPreviousEducationDocument() {
      this.infoStudentPreviousEducationDocumentDisplay = false

      let data = await this.UPDATE_STUDENT_PREVIOUS_EDUCATION_DOCUMENT()

      if (data) {
        this.$message({title: 'Добавлено', text: 'Данные успешно добавились'})
        this.loading = true
        await this.GET_STUDENTS_LIST({course_id: this.course_id, name: this.name})
        this.loading = false;
        await this.SET_OBJECT_EDUCATION_DOCUMENT_NULL();
      }
    },
    changePreviousEducationDocumentData(property, e, val = 'value') {
      let value = e.target[val]
      console.log(value, "value")
      this.SET_STUDENT_PREVIOUS_EDUCATION_DOCUMENT_DATA({property, value})
    },

    async savePreviousEducationDocument() {
      this.addStudentPreviousEducationDocumentDisplay = false

      let data = await this.POST_STUDENT_PREVIOUS_EDUCATION_DOCUMENT()

      if (data) {
        this.$message({title: 'Добавлено', text: 'Данные успешно добавились'})
        this.loading = true
        await this.GET_STUDENTS_LIST({course_id: this.course_id, name: this.name})
        this.loading = false;
      }
    },

    // Предыдущий документ об университет

    addStudentEnteredUniversity(student_data_id) {
      console.log(student_data_id, 'student_data_id')
      this.addStudentEnteredUniversityDisplay = true
      this.SET_ENTERED_UNIVERSITY_STUDENT_ID(student_data_id)
    },
    closeStudentEnteredUniversity() {
      this.addStudentEnteredUniversityDisplay = false
    },
    openInfoStudentEnteredUniversity(student_data_id) {
      console.log(student_data_id, 'info student_data_id')
      this.infoStudentId = student_data_id
      this.infoStudentEnteredUniversityDisplay = true
      this.SET_ENTERED_UNIVERSITY_STUDENT_ID(student_data_id)
    },
    closeInfoStudentEnteredUniversity() {
      this.infoStudentEnteredUniversityDisplay = false
      this.SET_OBJECT_UNIVERSITY_DOCUMENT_NULL()
    },
    async updateInfoStudentEnteredUniversity() {
      this.infoStudentEnteredUniversityDisplay = false

      let data = await this.UPDATE_STUDENT_ENTERED_UNIVERSITY()

      if (data) {
        this.$message({title: 'Добавлено', text: 'Данные успешно добавились'})
        this.loading = true
        await this.GET_STUDENTS_LIST({course_id: this.course_id, name: this.name})
        this.loading = false;
        await this.SET_OBJECT_UNIVERSITY_DOCUMENT_NULL();
      }
    },
    changeEnteredUniversityData(property, e, val = 'value') {
      let value = e.target[val]
      console.log(value, "value")
      this.SET_STUDENT_ENTERED_UNIVERSITY_DATA({property, value})
    },

    async saveEnteredUniversity() {
      this.addStudentEnteredUniversityDisplay = false

      let data = await this.POST_STUDENT_ENTERED_UNIVERSITY()

      if (data) {
        this.$message({title: 'Добавлено', text: 'Данные успешно добавились'})
        this.loading = true
        await this.GET_STUDENTS_LIST({course_id: this.course_id, name: this.name})
        this.loading = false;
      }
    },

    async getStudentList() {
      await this.GET_STUDENTS_LIST({course_id: this.course_id, name: this.name, page: 1})
    }
  },
  async mounted() {
    await this.GET_STUDENTS_LIST({course_id: this.course_id, name: this.name, page: this.page})
    this.loading = false
    await this.GET_STUDY_LEVEL_NAMES()
  }
}
</script>

<style scoped>

</style>
